// CSS
import styles from "./slideshow.module.scss";

// Constants
const atgCloudSrc = require("../../media/atg-cloud.png");

export const Slideshow = ({ slideshowImage }) => {
    return (
        <div className={styles.slideshow}>
            <div className={styles.display}>
                <div className={styles.circle}>
                    <img
                        alt="ATG Cloud"
                        className={styles.image}
                        src={atgCloudSrc}
                    />
                </div>
                <div className={styles.container}>
                    <img
                        alt="Slideshow"
                        className={styles.image}
                        src={slideshowImage}
                    />
                </div>
            </div>
        </div>
    );
};
