import { useState } from "react";

// Components
import { Navigation } from "./components/Navigation/Navigation";
import { Slideshow } from "./components/Slideshow/Slideshow";

// CSS
import styles from "./app.module.scss";

// Constants

const slideshowImageMap = {
    content: require("./media/tai-2.jpeg"),
    festival: require("./media/charli.jpg"),
    mgmt: require("./media/crowd.jpg"),
    studios: require("./media/prince.jpg"),
};

function App() {
    const [slideshowImage, setSlideshowImage] = useState(
        require("./media/charli.jpg")
    );

    function changeSlideshowImage(type) {
        return setSlideshowImage(slideshowImageMap[type]);
    }

    return (
        <div className={styles.app}>
            <Navigation changeSlideshowImage={changeSlideshowImage} />
            <Slideshow slideshowImage={slideshowImage} />
        </div>
    );
}

export default App;
