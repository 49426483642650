import styles from "./navigation.module.scss";

// Constants
const atgLogoSrc = require("../../media/atg-logo.png");

export const Navigation = ({ changeSlideshowImage }) => {
    function renderHeader() {
        return (
            <div className={styles.header}>
                <div className={styles.logo}>
                    <img
                        alt="ATG logo"
                        className={styles.image}
                        src={atgLogoSrc}
                    />
                </div>
                <div className={styles.connect}>
                    <a
                        className={styles.link}
                        href="mailto:will@allthingsgo.world"
                    >
                        CONNECT
                    </a>
                </div>
            </div>
        );
    }

    function renderLinks() {
        return (
            <div className={styles.links}>
                <div className={styles.item}>
                    <div className={styles.number}>
                        <span>01</span>
                    </div>
                    <div className={styles.text}>
                        <a
                            className={styles.link}
                            href="https://allthingsgofestival.com"
                            onMouseEnter={() =>
                                changeSlideshowImage("festival")
                            }
                            rel="noreferrer"
                            target="_blank"
                        >
                            FESTIVAL
                        </a>
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.number}>
                        <span>02</span>
                    </div>
                    <div className={styles.text}>
                        <a
                            className={styles.link}
                            href="https://allthingsgostudios.com"
                            onMouseEnter={() => changeSlideshowImage("studios")}
                            rel="noreferrer"
                            target="_blank"
                        >
                            STUDIOS
                        </a>
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.number}>
                        <span>03</span>
                    </div>
                    <div className={styles.text}>
                        <a
                            className={styles.link}
                            href="https://allthingsgomusic.com"
                            onMouseEnter={() => changeSlideshowImage("content")}
                            rel="noreferrer"
                            target="_blank"
                        >
                            CONTENT
                        </a>
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.number}>
                        <span>04</span>
                    </div>
                    <div className={styles.text}>
                        <span
                            className={styles.link}
                            onMouseEnter={() => changeSlideshowImage("mgmt")}
                        >
                            MGMT
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.navigation}>
            {renderHeader()}
            {renderLinks()}
        </div>
    );
};
